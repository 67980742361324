import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
// import styled from "styled-components";
import TableRow from "@mui/material/TableRow";
import DataTableBodyCell from "./DataTableBodyCell";
import DataTableHeadCell from "./DataTableHeadCell";
import { DragOverlay } from "@dnd-kit/core";

export const DraggableTableRow = ({ row, maxWidth }) => {
  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging
  } = useSortable({
    id: row.original.id
  });
  return (
    isDragging ?
    <DragOverlay>
    <TableRow
    {...row.getRowProps()}
      sx={{
        outline: "solid",
        overflow: "hidden",
        boxShadow: "rgb(0 0 0 / 10%) 0px 20px 25px -5px,rgb(0 0 0 / 30%) 0px 10px 10px -5px",
        maxHeight: "55px",
        maxWidth: maxWidth,
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
          cursor: "grabbing",
        }
      }}      
    >
      {row.cells.map((cell) => (
        <DataTableBodyCell
          sx={{
            background: "white",
            overflow: "hidden"
          }}
          key={row.id}
          align={cell.column.align ? cell.column.align : "left"}
          {...cell.getCellProps()}
        >
          {cell.render("Cell")}&nbsp;
        </DataTableBodyCell>
      ))}
    </TableRow>
    </DragOverlay> :
    <TableRow
      ref={setNodeRef}
      sx={{
        transform: CSS.Transform.toString(transform),
        transition: transition,
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
          cursor: "grab",
        }
      }}
      {...attributes} {...listeners}
      {...row.getRowProps()}
    >
      {row.cells.map((cell) => (
        cell.column.isHeader ? (
          <DataTableHeadCell
              key={row.id}
              disableSorting={true}
              sorted={false}
              align={cell.column.align ? cell.column.align : "left"}
              {...cell.getCellProps()}
          >
              {cell.render("Cell")}
          </DataTableHeadCell>
          ) : (
          <DataTableBodyCell
            key={row.id}
            align={cell.column.align ? cell.column.align : "left"}
            {...cell.getCellProps()}
          >
            {cell.render("Cell")}
          </DataTableBodyCell>
          )
      ))}
    </TableRow>

  )
};
